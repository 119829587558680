<template>
  <div @click="snackbar = false">
    <v-app-bar
        color="deep-purple accent-4"
        :fixed="true"
        dense
        dark>
      <div style="margin-right: 15px;" @click="back">
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <v-toolbar-title>TMS Order Task</v-toolbar-title>
    </v-app-bar>

    <div
        style="height: calc(100% - 50px - 56px); overflow-y: auto; margin-top: 48px; margin-bottom: 56px; width: 100%;"
    >
      <v-tabs style="width: 100%;"
              grow
              v-model="tab_select">
        <v-tab>All</v-tab>
        <v-tab>Loading</v-tab>
        <v-tab>Unloading</v-tab>
      </v-tabs>

      <div style="display: flex; justify-content: center; margin-top: 10px;">
        <div style="width: 100%;text-align: center;">
          <v-btn color="primary" style="width: 30%;margin: 3px;padding: 18px;" @click="setTaskStatus()">
            Set Status
          </v-btn>
          <v-btn color="primary" style="width: 30%;margin: 3px;padding: 18px;" @click="saveNsGoodsStatus(12)">
            Set DA
          </v-btn>
          <v-btn color="primary" style="width: 30%;margin: 3px;padding: 18px;" @click="saveNsGoodsStatus(4)">
            Set UD
          </v-btn>
        </div>
      </div>

      <v-list two-line>
        <v-list-item-group
            multiple
        >
          <template v-for="(item, index) in items">
            <v-list-item :key="item.id + 'v-list-item'">
              <template v-slot:default>
                <v-list-item-action class="li-left-box">
                  <input type="checkbox" @click="selectTaskData(item.id)">
                  <v-list-item-action-text v-text="'#' + item.order_in_tms_route"></v-list-item-action-text>
                </v-list-item-action>
                <v-list-item-content @click="goTmsOrderTaskDetail(item.tms_order_id, item.id)">
                  <v-list-item-title
                      v-text="'Order Number: ' + tms_orders[item.tms_order_id]['tms_order_detail']['order_number'] + ' | '+item.task_type_str + ' | [' + item.status_str + ']'"></v-list-item-title>
                  <v-list-item-title v-text="'Task Company Name: ' + item.name"></v-list-item-title>
                  <v-list-item-title
                      v-text="'Total Collo: ' + item.coTotal + ' | Total Weight: ' + item.weightTotal"></v-list-item-title>
                  <v-list-item-subtitle class="text--primary" v-text="strOrderDisplay(item)"></v-list-item-subtitle>
                  <v-list-item-subtitle v-if="tms_orders[item.tms_order_id]['shipment_mawb'] !== null" class="text--primary" v-text="'AWB/Reference: ' + tms_orders[item.tms_order_id]['shipment_mawb']"></v-list-item-subtitle>
                  <v-list-item-subtitle class="text--primary" v-text="'Client: ' + tms_orders[item.tms_order_id]['tms_order_detail']['client_code'] + ' - ' + tms_orders[item.tms_order_id]['tms_order_detail']['client_name']"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="strOrderDisplay2(item)"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="'Instruction: ' + item.instruction"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-divider
                v-if="index + 1 < items.length"
                :key="item.id + 'v-divider'"
            ></v-divider>
          </template>

          <v-list-item :key="'next_item_btn_3'" v-if="show_no_data">
            <template v-slot:default>
              <v-list-item-content>
                <v-list-item-title style="color: #1867c0;" v-text="'No Data'"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <template v-if="items.length > 0">
            <v-divider
                :key="'v-divider-next_item_btn_top'"
            ></v-divider>

            <v-list-item :key="'next_item_btn_2'">
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title style="color: #1867c0;" v-text="'End'"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>

        </v-list-item-group>
      </v-list>
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" style="font-size: larger;position: absolute;top: -50%">
      {{ snackbar_txt }}
    </v-snackbar>

  </div>
</template>

<script>
import {get, post} from '@/utils/request.js'
import {EH_MOBILE_FUN, EH_MOBILE_GLOBAL} from '@/helper.js'
import global_ from '@/utils/Global'

export default {
  data() {
    return {
      snackbar: false,
      snackbar_txt: '',
      snackbar_timeout: 0,
      null_val: null,

      show_no_data: false,
      tab_id_to_status: {
        0: 'all',
        1: 'loading',
        2: 'unloading'
      },

      // page: 1,
      tms_route_id: null,
      select_task:[],
      has_next_page: true,
      items: [],
      tms_orders: {},
      tab_select: 0
    }
  },
  watch: {
    tab_select(newval, oldval) {
      this.null_val = newval
      this.null_val = oldval
      this.items = []
      this.select_task = []
      this.getTmsOrderList()

      global_.current_tms_task_table = newval
    },
  },
  methods: {
    getTmsOrderList: function () {
      let url = EH_MOBILE_GLOBAL.get_tms_order_list + '?tms_route_id=' + this.tms_route_id + '&task_status=' + this.tab_id_to_status[this.tab_select]
      get(url, null, {}).then((response) => {
        let data = response.data
        if (data.status != 'success') {
          this.snackbar_txt = data.reason
          this.snackbar = true
          return
        }

        this.items = data.tasks
        this.tms_orders = data.orders
        if (this.items.length <= 0) {
          this.show_no_data = true
        } else {
          this.show_no_data = false
        }
      })
    },
    strOrderDisplay: function (info) {
      return info['street'] + ' ' + info['number'] + ', ' + info['postal_code'] + ', ' + info['place'] + ', ' + info['country_code']
    },
    strOrderDisplay2: function (info) {
      return info['date_time_begin'].substr(0, info['date_time_begin'].length - 3) + ' - ' +
          info['date_time_end'].substr(0, info['date_time_end'].length - 3) //info['date_time_end']
    },
    goTmsOrderDetail: function (tms_order_id) {
      let path = '/tms/order/detail/' + this.tms_route_id + '/' + tms_order_id
      // console.log(path)
      this.$router.push({
        path: path
      })
    },
    goTmsOrderTaskDetail: function (tms_order_id, tms_order_task_id) {
      let path = '/tms/order/task/detail/' + this.tms_route_id + '/' + tms_order_id + '/' + tms_order_task_id
      this.$router.push({
        path: path
      })
    },
    sMsg(txt) {
      this.snackbar_txt = txt
      this.snackbar = true
      return
    },
    back: function () {
      this.$router.go(-1)
    },
    selectTaskData(taskId){
      var arrIndex = this.select_task.indexOf(taskId);

      if (arrIndex > -1) {
        this.select_task.splice(arrIndex,1);
      } else {
        this.select_task.push(taskId);
      }

      console.log(this.select_task.join('-'))
    },
    setTaskStatus(){
      if(this.select_task.length < 1){
        this.sMsg('Please check the data first')
        return
      }

      var selectTaskStr = this.select_task.join('-')

      let path = '/tms/multiple/order/task/status/' + this.tms_route_id + '/' + selectTaskStr
      this.$router.push({
        path: path
      })
    },
    saveNsGoodsStatus(ns_goods_status) {
      if(this.select_task.length < 1){
        this.sMsg('Please check the data first')
        return
      }

      var selectTaskStr = this.select_task.join('-')

      let url = EH_MOBILE_GLOBAL.tdm_save_ns_goods_status
      let data = EH_MOBILE_FUN.serialize({
        'tms_route_id': this.tms_route_id,
        'tms_order_task_id': selectTaskStr,
        'ns_goods_status': ns_goods_status,
        'from': 'multiple'
      })
      post(url, data).then((response) => {
        if (response.data.status === 'success') {
          this.sMsg('Modified status successfully')
          this.getTmsOrderList()
        } else {
          this.sMsg(response.data.reason)
        }
      }).catch((error) => {
        console.log(error)
        this.sMsg('Sorry, something is wrong')
      })
    }
  },
  created() {
    if (global_.current_tms_task_table > 0) {
      this.tab_select = global_.current_tms_task_table
    }
    this.tms_route_id = this.$route.params.tms_route_id
    this.getTmsOrderList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div >>> .v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__prev {
  display: none !important;
}

.v-application--is-ltr .li-left-box {
  margin-right: 5px;
}

div >>> .v-list-item.v-list-item--link.theme--light {
  padding-right: 8px;
  padding-left: 8px;
}
</style>
